import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import { Element, Link as Ir } from 'react-scroll';
import Accordion from 'react-bootstrap/Accordion';
//import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function App() {
  //const [scroll, setScroll] = useState(0);
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");
  //const [index, setIndex] = useState(0);
  //const [verMenu, setVerMenu] = useState('no_ver_menu');

  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [equipo, setEquipo] = useState("");

  useEffect(() => {
    /*
    window.addEventListener("scroll", (e) => {
      setScroll(window.scrollY)
    });
    */

    return () => {
      if(window.location.href.substr(0, 5)==='http:' && window.location.href!=='http://localhost:3000/'){
        window.location.href = window.rutas;
      }
    };
  }, []);

  return (
    <div>
      <Element name="inicio"></Element>
      <div className="menu" id="menu">
        <div className="max_width_menu">
          <div className="menu_flex">
            <img src="img/logo.svg" className="logo" title={window.nombre} alt={window.nombre}/>
            <div className="menu_inicio">
              Servicio a caminadoras y gimnasios<br/>en todo México 55 5513 4050 
            </div>
            <div className="flex_whats">
              <a href={window.whats} target="new" className="whats">
                <img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/>
              </a>
              <a href={window.whats} target="new" className="caja_whats">
                ¿Necesitas ayuda?
                <br/>
                Habla con nosotros
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="fondo_inicial">
        <div className="max_width padding_width">
          <div className="flex_inicio">
            <div className="f_inicio_uno"></div>
            <div className="f_inicio_dos">
              <div className="sale_menu_inicio">
                Servicio a caminadoras y gimnasios en todo México 55 5513 4050 
              </div>
              <h9>
                Caminadoras, Bicicletas, Elípticas PROFORM, NORDICTRACK
                <br/>
                ¿TIENES ALGUNA FALLA?
              </h9>
              <br/>
              SOMOS CENTRO DE SERVICIO AUTORIZADO
              <br/>
              Más de 20 años de experiencia nos respaldan.
              <br/><br/>
              <h10>
                REPARACIÓN, SERVICIO Y MANTENIMIENTO
              </h10>
              <form data-aos="fade-down">
                <center><h11>CONTÁCTANOS</h11></center>
                <div>
                  <label>*Nombre:</label>
                  <input type="text" name="nombre" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre" value={nombre} required/>
                </div>
                <div>
                  <label>Estado:</label>
                  <select name="estado" onChange={(e) => setEstado(e.target.value)}>
                    <option value="" selected={estado==="" ? true : false}></option>
                    {
                      window._my_estado.map((item, i) =>
                        <option value={item.estado} key={i} selected={item.estado===estado ? true : false}>{item.estado}</option>
                      )
                    }
                  </select>
                </div>
                {
                  estado ?
                    <div>
                      <label>{estado==="Ciudad de México" ? "Alcaldía" : "Municipio"}:</label>
                      <select name="municipio" onChange={(e) => setMunicipio(e.target.value)}>
                        <option value="" selected={municipio==="" ? true : false}></option>
                        {
                          window._my_estado.filter(p => p.estado===estado).map(esta =>
                            esta.municipios.map((item, i) =>
                              <option value={item} key={i} selected={item===municipio ? true : false}>{item}</option>
                            )
                          )
                        }
                      </select>
                    </div>
                  :
                    null
                }
                <div>
                  <label>Equipo:</label>
                  <select name="equipo" onChange={(e) => setEquipo(e.target.value)}>
                    <option value="" selected={equipo==="" ? true : false}></option>
                    {
                      window._my_equipo.map((item, i) =>
                        <option value={item.nombre} key={i} selected={item.nombre===equipo ? true : false}>{item.nombre}</option>
                      )
                    }
                  </select>
                </div>
                <div>
                  <label>*Correo electrónico:</label>
                  <input type="text" name="correo" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo electrónico" value={correo} required/>
                </div>
                <div>
                  <label>*Teléfono:</label>
                  <input type="text" name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="10 digitos" value={telefono} required/>
                </div>
                <div>
                  <label>*Mensaje:</label>
                  <textarea name="mensaje" id="area" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje:" value={mensaje} required/>
                </div>
                <button onClick={(e) => {
                  e.preventDefault();
                  if(nombre===''){
                    alerta('error','Ingresa tu nombre completo');
                  }else if(telefono===''){
                    alerta('error','Ingresa tu teléfono o número celular');
                  }else if(correo===''){
                    alerta('error','Ingresa tu correo electrónico');
                  }else if(window.validarMail(correo)===false){
                    alerta('error','Ingrese un correo electrónico valido');
                  }else if(mensaje===''){
                    alerta('error','Escribe un mensaje');
                  }else{
                    window.H5_loading.show();
                    var params = new URLSearchParams();
                    params.append('nombre', nombre);
                    params.append('correo', correo);
                    params.append('mensaje', mensaje);
                    params.append('telefono', telefono);
                    params.append('estado', estado);
                    params.append('municipio', municipio);
                    params.append('equipo', equipo);
                    
                    Axios.post(`${window.envia}`, params)
                      .then(response => {
                        if(response.data.elError===1){
                          alerta('success',response.data.mensaje)
                          setNombre("");
                          setCorreo("");
                          setTelefono("");
                          setMensaje("");
                          setEstado("");
                          setMunicipio("");
                          setEquipo("");
                        }else{
                          alerta('error',response.data.mensaje)
                        }
                      })
                      .catch(error => {
                        //window.alerta('error','LOLERO'+error);
                        console.log(error)
                      })
                      .then(function() {
                        window.H5_loading.hide();
                      })
                  }
                }}>Enviar mensaje</button>
                <div id="cl"/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="fondo_contacto">
        <div className="max_width padding_width">
          <center>
            <div data-aos="fade-down">
              Somos el principal Centro de Servicio especializado en México de equipos de fitness en el hogar. Centro se servicio Liverpool, Sears, Martí, Costco, Sams, Proform, Nordictrack
              <br/><br/>
              <b>100 % seguridad  100%  garantía certificada</b>
            </div>
            <div className="flex_tres">
              <a href="https://caminadorasygimnasios.com/pages/bandasylubricantes2024" target="new" data-aos="fade-right">
                <img src="img/b_1.svg" title="Lubricantes, bandas y refacciones" alt="Lubricantes, bandas y refacciones"/>
                Lubricantes, bandas y refacciones
              </a>
              <a href="https://caminadorasygimnasios.com/pages/tiendaenlinea2024" target="new" data-aos="fade-down">
                <img src="img/b_2.svg" title="Tienda en línea de equipos" alt="Tienda en línea de equipos"/>
                Tienda en línea de equipos
              </a>
              <a href="https://caminadorasygimnasios.com/pages/garantias" target="new" data-aos="fade-left">
                <img src="img/b_3.svg" title="Garantía extendida" alt="Garantía extendida"/>
                Garantía extendida
              </a>
            </div>
           </center>
        </div>
      </div>
      <div className="pie">
        <div className="max_width padding_pie">
          <div className="mi_pie alinear">
            <div className="flex_start alinear">
              <Ir to="inicio" className="subir">➤</Ir>
              {window.nombre} © {window.ano}

            </div>
            <a href="https://www.adsvega.com/" target="new" className="vega grises">
              <img src="img/vega.svg?v=0" title="ecommerce web design" alt="ecommerce web design"/>
            </a>
            <div>
              <div className="flex_end alinear">
                <div className="flex_tel alinear">
                  <img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/>
                  <a href={`tel:${window.telefonoM}`} target="new">
                    {window.telefono}
                  </a>
                    y  
                  <a href={`tel:${window.telefonoDosM}`} target="new">
                    {window.telefonoDos}
                  </a>
                </div>
                <a href={`mailto:${window.correo}`} target="new" className="flex_tel alinear">
                  <img src="img/con_3.svg" title="Teléfono" alt="Teléfono"/>
                  {window.correo}
                </a>
              </div>
              <a href={window.ubicacion} target="new" className="flex_tel alinear m_ubicacion">
                <img src="img/con_2.svg" title="Ubicación" alt="Ubicación"/>
                {window.domicilio}
              </a>
              <div className="para_aviso">
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("preguntas")
                }}>Preguntas frecuentes</div>
                  
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("aviso")
                }}>Aviso de privacidad</div>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Aviso de privacidad</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="at_j">
                  Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que <b>TECNOSERVICIO, CAMINADORAS Y GIMNASIOS</b> con domicilio en <b>Calle 4 No. 36 Col. San Pedro de los Pinos Deleg. Álvaro Obregón, CDMX C.P. 01180</b> es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
                  <br/><br/>
                  Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
                  <br/><br/>
                  Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
                  <ul className="lista_avi">
                    <li>Nombre completo</li>
                    <li>Teléfono fijo y/o celular</li>
                    <li>Correo electrónico</li>
                    <li>Dirección para envíos y garantías</li>
                  </ul>
                  Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado.
                  <br/><br/>
                  Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su Art. 29 a Arturo Vega, responsable de nuestro Departamento de Protección de Datos Personales, ubicado en Calle 4 No. 36 Col. San Pedro de los Pinos Deleg. Álvaro Obregón, CDMX C.P. 01180 o bien, se comunique al teléfono (01 55) 5513-4050 o vía correo electrónico a ecommerce@caminadorasygimnasios.com, el cual solicitamos confirme vía telefónica para garantizar su correcta recepción.
                  <br/><br/>
                  En caso de que no desee de recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica: ecommerce@caminadorasygimnasios.com y en un plazo máximo de 5 días hábiles le informaremos vía mail la procedencia de la misma.
                  <br/><br/>
                  Importante: Cualquier modificación a este Aviso de Privacidad podrá consultarlo en<br/><b>http://www.caminadorasygimnasios.com /aviso-de-privacidad</b>
                  <br/><br/>
                  Fecha de última actualización: <b>15/05/2018</b>
                </div>
              </Modal.Body>
            </>
          : servicio==='preguntas' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Preguntas frecuentes</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion defaultActiveKey="0">
                  {
                    window._my_preguntas.map((item, i) =>
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{item.id}. {item.pregunta}</Accordion.Header>
                        <Accordion.Body>
                          {item.respuesta}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Modal.Body>
            </>
          :
            null
        }
      </Modal>
    </div>
  );
}

export default App;
